// src/App.js
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import Navbar from './components/layout/Navbar';
import HeroSection from './components/hero/HeroSection';
import Home from './pages/Home';
import PatternDetail from './components/patterns/PatternDetail';

function App() {
  return (
    <div className="min-h-screen bg-[#131316]">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:company/:category/:interaction" element={<PatternDetail />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;