// src/components/patterns/PatternModal.js
import React, { useCallback, useEffect, useRef } from 'react';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

const PatternModal = ({ pattern, onClose, onNext, onPrevious }) => {
  const modalRef = useRef(null);

  const handleOutsideClick = useCallback((e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [handleOutsideClick]);

  if (!pattern) return null;

  const playerParams = new URLSearchParams({
    autoplay: '1',
    muted: '0',
    controls: '1',
    playsinline: '1'
  }).toString();

  const embedUrl = `${pattern['Interaction link']}?${playerParams}`;

  return (
    <div className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center">
      <div 
        ref={modalRef}
        className="relative w-full max-w-6xl mx-4 bg-[#1c1c1c] rounded-lg overflow-hidden"
      >
        <div className="absolute top-0 left-0 right-0 p-4 flex justify-between items-center z-10">
          <h2 className="text-white">
            {pattern['Interaction name']} - {pattern.Company}
          </h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="relative pt-[56.25%]">
          <iframe
            src={embedUrl}
            className="absolute inset-0 w-full h-full"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={pattern['Interaction name']}
          />
        </div>

        <div className="p-4 space-y-4 text-sm">
          <div className="flex items-center text-gray-400 space-x-2">
            <span>{pattern.Device}</span>
            <span>•</span>
            <span>{pattern['Interaction category']}</span>
          </div>
        </div>

        {onPrevious && (
          <button 
            onClick={onPrevious}
            className="absolute left-4 top-1/2 -translate-y-1/2 p-2 bg-black/50 rounded-full text-white hover:bg-black/70"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
        )}
        {onNext && (
          <button 
            onClick={onNext}
            className="absolute right-4 top-1/2 -translate-y-1/2 p-2 bg-black/50 rounded-full text-white hover:bg-black/70"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        )}
      </div>
    </div>
  );
};

export default PatternModal;