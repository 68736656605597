import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <nav className="w-full bg-transparent absolute top-0 left-0 px-4 md:px-6 py-4 z-50">
      <div className="max-w-7xl mx-auto flex justify-between items-center">
        {/* Logo */}
        <a href="/" className="text-white text-lg font-medium hover:text-gray-300 transition-colors">
          AI Collective
        </a>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center gap-6">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdCcqRUs7V0onwE371Ej1XHngTPZzASywICtOsmbdY0bztpew/viewform?usp=dialog"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-gray-300 transition-colors"
          >
            Send feedback
          </a>
          <span className="text-gray-400 cursor-not-allowed">
            Blog (Coming soon)
          </span>
        </div>

        {/* Mobile Menu Button */}
        <button 
          className="md:hidden text-white"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
        </button>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="absolute top-16 left-0 right-0 bg-black/80 backdrop-blur-sm p-4 md:hidden z-50">
            <div className="flex flex-col items-center space-y-4">
              <a
                href="https://www.formlite.co/public-form/1FAIpQLSdCcqRUs7V0onwE371Ej1XHngTPZzASywICtOsmbdY0bztpew?template=Typeform"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white hover:text-gray-300 transition-colors"
              >
                Send feedback
              </a>
              <span className="text-gray-400 cursor-not-allowed">
                Blog (Coming soon)
              </span>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;