// import React, { useState } from 'react';
// import { usePatterns } from '../hooks/usePatterns';
// import HeroSection from '../components/hero/HeroSection';
// import CategoryFilters from '../components/patterns/CategoryFilters';
// import PatternGrid from '../components/patterns/PatternGrid';
// import PatternModal from '../components/patterns/PatternModal';
// import { ChevronDown } from 'lucide-react';

// const Home = () => {
//   const {
//     patterns,
//     categories,
//     selectedCategory,
//     setSelectedCategory,
//     loading,
//     error
//   } = usePatterns();

//   const [selectedPattern, setSelectedPattern] = useState(null);
//   const [sortBy, setSortBy] = useState('latest');

//   const handlePatternClick = (pattern) => {
//     setSelectedPattern(pattern);
//   };

//   const handleSortChange = (e) => {
//     setSortBy(e.target.value);
//   };

//   const getSortedPatterns = () => {
//     return [...patterns].sort((a, b) => {
//       switch (sortBy) {
//         case 'latest':
//           return parseInt(b['Serial number'].replace('#', '')) - 
//                  parseInt(a['Serial number'].replace('#', ''));
//         case 'oldest':
//           return parseInt(a['Serial number'].replace('#', '')) - 
//                  parseInt(b['Serial number'].replace('#', ''));
//         default:
//           return 0;
//       }
//     });
//   };

//   if (loading) return (
//     <div className="min-h-screen bg-[#131316] flex items-center justify-center">
//       <div className="text-white text-xl">Loading patterns...</div>
//     </div>
//   );

//   if (error) return (
//     <div className="min-h-screen bg-[#131316] flex items-center justify-center">
//       <div className="text-red-500 text-xl">Error loading patterns: {error}</div>
//     </div>
//   );

//   const sortedPatterns = getSortedPatterns();

//   return (
//     <div className="min-h-screen bg-[#131316]">
//       <HeroSection />
      
//       <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
//         <CategoryFilters
//           categories={categories}
//           activeCategory={selectedCategory}
//           onCategoryChange={setSelectedCategory}
//         />

//         <div>
//           <div className="flex justify-between items-center mb-6">
//             <h2 className="text-2xl font-bold text-white">
//               {selectedCategory === 'All' ? 'All Patterns' : `${selectedCategory} Patterns`}
//             </h2>
            
//             <div className="flex items-center gap-4">
//               <span className="text-gray-400 text-sm">
//                 {sortedPatterns.length} pattern{sortedPatterns.length !== 1 ? 's' : ''}
//               </span>
              
//               <div className="relative">
//                 <select
//                   value={sortBy}
//                   onChange={handleSortChange}
//                   className="appearance-none px-4 py-2 pr-10 rounded-lg text-sm font-medium
//                            border border-[#2A2929] bg-[#272729] text-white
//                            hover:bg-opacity-80 cursor-pointer focus:outline-none"
//                 >
//                   <option value="latest">Latest First</option>
//                   <option value="oldest">Oldest First</option>
//                 </select>
//                 <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 w-4 h-4 text-white pointer-events-none" />
//               </div>
//             </div>
//           </div>
          
//           <PatternGrid 
//             patterns={sortedPatterns}
//             onPatternClick={handlePatternClick}
//           />
//         </div>
//       </main>

//       {selectedPattern && (
//         <PatternModal
//           pattern={selectedPattern}
//           onClose={() => setSelectedPattern(null)}
//         />
//       )}
//     </div>
//   );
// };

// export default Home;

// src/pages/Home.js
import React, { useState } from 'react';
import { usePatterns } from '../hooks/usePatterns';
import HeroSection from '../components/hero/HeroSection';
import CategoryFilters from '../components/patterns/CategoryFilters';
import PatternGrid from '../components/patterns/PatternGrid';
import PatternModal from '../components/patterns/PatternModal';
import CategoryDescription from '../components/patterns/CategoryDescription';
import { ChevronDown } from 'lucide-react';

const Home = () => {
  const {
    patterns,
    categories,
    selectedCategory,
    setSelectedCategory,
    loading,
    error
  } = usePatterns();

  const [selectedPattern, setSelectedPattern] = useState(null);
  const [sortBy, setSortBy] = useState('latest');

  const handlePatternClick = (pattern) => {
    setSelectedPattern(pattern);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const getSortedPatterns = () => {
    return [...patterns].sort((a, b) => {
      switch (sortBy) {
        case 'latest':
          return parseInt(b['Serial number'].replace('#', '')) - 
                 parseInt(a['Serial number'].replace('#', ''));
        case 'oldest':
          return parseInt(a['Serial number'].replace('#', '')) - 
                 parseInt(b['Serial number'].replace('#', ''));
        default:
          return 0;
      }
    });
  };

  if (loading) return (
    <div className="min-h-screen bg-[#131316] flex items-center justify-center">
      <div className="text-white text-xl">Loading patterns...</div>
    </div>
  );

  if (error) return (
    <div className="min-h-screen bg-[#131316] flex items-center justify-center">
      <div className="text-red-500 text-xl">Error loading patterns: {error}</div>
    </div>
  );

  const sortedPatterns = getSortedPatterns();

  return (
    <div className="min-h-screen bg-[#131316]">
      <HeroSection />
      
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <CategoryFilters
          categories={categories}
          activeCategory={selectedCategory}
          onCategoryChange={setSelectedCategory}
        />

        <div>
        <div className="mb-8">
  <div className="flex justify-between items-center mb-3">
    <div className="flex items-center gap-6">
      <h2 className="text-2xl font-bold text-white">
        {selectedCategory === 'All' ? 'All Patterns' : `${selectedCategory} Patterns`}
      </h2>
      {/* Pattern count - hidden on mobile */}
      {/* <span className="text-gray-400 text-sm hidden md:inline">
        {sortedPatterns.length} pattern{sortedPatterns.length !== 1 ? 's' : ''}
      </span> */}
    </div>
    
    <div className="relative">
      <select
        value={sortBy}
        onChange={handleSortChange}
        className="appearance-none px-4 py-2 pr-10 rounded-lg text-sm font-medium
                 border border-[#2A2929] bg-[#272729] text-white
                 hover:bg-opacity-80 cursor-pointer focus:outline-none"
      >
        <option value="latest">Latest First</option>
        <option value="oldest">Oldest First</option>
      </select>
      <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 w-4 h-4 text-white pointer-events-none" />
    </div>
  </div>

  <CategoryDescription category={selectedCategory} />
</div>
          
          <PatternGrid 
            patterns={sortedPatterns}
            onPatternClick={handlePatternClick}
          />
        </div>
      </main>

      {selectedPattern && (
        <PatternModal
          pattern={selectedPattern}
          onClose={() => setSelectedPattern(null)}
        />
      )}
    </div>
  );
};

export default Home;