import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const PatternCard = ({ pattern, onPatternClick }) => {
  const [logoError, setLogoError] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [shouldLoadVideo, setShouldLoadVideo] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setShouldLoadVideo(true);
          observer.disconnect();
        }
      },
      {
        rootMargin: '50px 0px',
      }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const getVideoEmbedUrl = (url, isPreview = false) => {
    if (!url) return '';
    
    if (url.includes('vimeo.com')) {
      let vimeoId;
      if (url.includes('player.vimeo.com')) {
        vimeoId = url.match(/video\/(\d+)/)?.[1];
      } else {
        vimeoId = url.match(/vimeo\.com(?:\/video)?\/(\d+)/)?.[1];
      }
      
      if (!vimeoId) return url;
      
      const params = new URLSearchParams({
        autoplay: '1',
        loop: '1',
        byline: '0',
        title: '0',
        muted: '1',
        controls: isPreview ? '0' : '1',
        quality: isPreview ? '540p' : '720p',
        portrait: '0',
        sidedock: '0',
        transparent: '1',
        playsinline: '1',
        background: isPreview ? '1' : '0',
        autopause: '0',
        preload: 'auto',
        dnt: '1',
        loading: 'lazy'
      }).toString();

      return `https://player.vimeo.com/video/${vimeoId}?${params}`;
    }
    
    if (url.includes('drive.google.com')) {
      const matches = url.match(/\/d\/(.+?)\/view/);
      const fileId = matches ? matches[1] : null;
      return fileId ? `https://drive.google.com/file/d/${fileId}/preview?hq=true` : url;
    }

    return url;
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  const getClearbitLogoUrl = (company) => {
    if (!company) return '';
    return `https://logo.clearbit.com/${company.toLowerCase().replace(/\s+/g, '')}.com`;
  };

  const handleLogoError = () => {
    setLogoError(true);
  };

  const getCompanyDisplay = () => {
    if (pattern.Company === 'NA') {
      return {
        initials: 'CO',
        name: 'Concept'
      };
    }
    return {
      initials: getCompanyInitials(pattern.Company),
      name: pattern.Company
    };
  };

  const getCompanyInitials = (company) => {
    if (!company) return '';
    return company
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const handleClick = () => {
    if (!pattern.Company || !pattern['Interaction category'] || !pattern['Interaction name']) return;
    
    const slug = `/${pattern.Company.toLowerCase().replace(/\s+/g, '-')}/${pattern['Interaction category'].toLowerCase().replace(/\s+/g, '-')}/${pattern['Interaction name'].toLowerCase().replace(/\s+/g, '-')}`;
    navigate(slug, { state: { from: location.pathname } });
  };

  if (!pattern) return null;

  const companyDisplay = getCompanyDisplay();

  return (
    <div 
      ref={cardRef}
      onClick={handleClick} 
      className="group relative border border-[#2b2b2b] bg-[#1b1b1f] rounded-xl overflow-hidden p-4 cursor-pointer"
    >
      {/* Company Logo + Serial Number */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center gap-2">
          <div className="w-8 h-8 rounded-lg bg-[#1A1A1A] flex items-center justify-center overflow-hidden">
            {pattern.Company === 'NA' ? (
              <span className="text-white text-sm font-medium">
                {companyDisplay.initials}
              </span>
            ) : logoError ? (
              <span className="text-white text-sm font-medium">
                {companyDisplay.initials}
              </span>
            ) : (
              <img
                src={getClearbitLogoUrl(pattern.Company)}
                alt={companyDisplay.name}
                className="w-full h-full object-contain p-1.5"
                onError={handleLogoError}
              />
            )}
          </div>
          <span className="text-gray-300 font-medium font-['JetBrains_Mono']">
            {companyDisplay.name}
          </span>
        </div>
        <span className="text-gray-300 text-sm font-['JetBrains_Mono']">{pattern['Serial number']}</span>
      </div>

      {/* Video Container with Hover Controls */}
      <div className="relative aspect-video rounded-sm overflow-hidden mb-4 bg-[#0D0D0F]">
        <div className="relative w-full h-full group/video">
          {pattern['Interaction link'] && shouldLoadVideo && (
            <>
              {!videoLoaded && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-6 h-6 border-2 border-gray-600 border-t-gray-400 rounded-full animate-spin" />
                </div>
              )}
              {/* Background video (always playing) */}
              <iframe
                src={getVideoEmbedUrl(pattern['Interaction link'], true)}
                className={`absolute inset-0 w-full h-full transition-opacity duration-300 ${
                  videoLoaded ? 'opacity-100' : 'opacity-0'
                }`}
                frameBorder="0"
                allowFullScreen
                allow="autoplay; fullscreen; picture-in-picture; muted"
                onLoad={handleVideoLoad}
              />
              {/* Hover video with controls */}
              <iframe
                src={getVideoEmbedUrl(pattern['Interaction link'], false)}
                className="absolute inset-0 w-full h-full opacity-0 group-hover/video:opacity-100 transition-opacity duration-300"
                frameBorder="0"
                allowFullScreen
                allow="autoplay; fullscreen; picture-in-picture; muted"
              />
            </>
          )}
        </div>
      </div>

      {/* Title and Tags */}
      <div className="space-y-4">
        <h3 className="text-white text-md font-medium">
          {pattern['Interaction name']}
        </h3>

        <div className="flex flex-wrap gap-3">
          {pattern.Device && (
            <span className="text-xs px-3 py-1 bg-[#1A1A1A] text-gray-300 rounded-lg font-['JetBrains_Mono']">
              {pattern.Device}
            </span>
          )}
          {pattern['Interaction category'] && (
            <span className="text-xs px-3 py-1 bg-[#1A1A1A] text-gray-300 rounded-lg font-['JetBrains_Mono']">
              {pattern['Interaction category']}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatternCard;