// src/hooks/usePatterns.js
import { useState, useEffect } from 'react';
import { fetchSheetData } from '../utils/sheetsHelper';

export const usePatterns = () => {
  const [patterns, setPatterns] = useState([]);
  const [categories, setCategories] = useState(['All']);
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadPatterns = async () => {
      try {
        setLoading(true);
        const data = await fetchSheetData();
        console.log('Loaded patterns:', data); // Debug log
        setPatterns(data);

        // Extract unique categories
        const uniqueCategories = ['All', ...new Set(data
          .map(item => item['Interaction category'])
          .filter(Boolean))];
        setCategories(uniqueCategories);
      } catch (err) {
        console.error('Error in usePatterns:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadPatterns();
  }, []);

  // Filter patterns based on selected category
  const filteredPatterns = selectedCategory === 'All'
    ? patterns
    : patterns.filter(pattern => pattern['Interaction category'] === selectedCategory);

  return {
    patterns: filteredPatterns,
    categories,
    selectedCategory,
    setSelectedCategory,
    loading,
    error
  };
};