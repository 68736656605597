import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft, ExternalLink, ChevronLeft, ChevronRight } from 'lucide-react';
import { fetchSheetData } from '../../utils/sheetsHelper';

const PatternDetail = () => {
  const { company, category, interaction } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [pattern, setPattern] = useState(null);
  const [loading, setLoading] = useState(true);
  const [logoError, setLogoError] = useState(false);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);
  const [videoLoading, setVideoLoading] = useState(true);
  const [allPatterns, setAllPatterns] = useState([]);
  const [prevPattern, setPrevPattern] = useState(null);
  const [nextPattern, setNextPattern] = useState(null);

  const updateNavigation = (patterns, currentPattern) => {
    if (!patterns || !currentPattern) return;
    
    const currentIndex = patterns.findIndex(p => p['Serial number'] === currentPattern['Serial number']);
    
    if (currentIndex > 0) {
      setPrevPattern(patterns[currentIndex - 1]);
    } else {
      setPrevPattern(null);
    }
    
    if (currentIndex < patterns.length - 1) {
      setNextPattern(patterns[currentIndex + 1]);
    } else {
      setNextPattern(null);
    }
  };

  useEffect(() => {
    const loadPattern = async () => {
      try {
        const data = await fetchSheetData();
        setAllPatterns(data);
        
        const foundPattern = data.find(p => 
          p.Company.toLowerCase().replace(/\s+/g, '-') === company &&
          p['Interaction category'].toLowerCase().replace(/\s+/g, '-') === category &&
          p['Interaction name'].toLowerCase().replace(/\s+/g, '-') === interaction
        );
        
        setPattern(foundPattern);
        updateNavigation(data, foundPattern);
      } catch (error) {
        console.error('Error loading pattern:', error);
      } finally {
        setLoading(false);
      }
    };

    loadPattern();
  }, [company, category, interaction]);

  const getVideoEmbedUrl = (url, isPreview = false) => {
    if (!url) return '';
    
    if (url.includes('vimeo.com')) {
      let vimeoId;
      if (url.includes('player.vimeo.com')) {
        vimeoId = url.match(/video\/(\d+)/)?.[1];
      } else {
        vimeoId = url.match(/vimeo\.com(?:\/video)?\/(\d+)/)?.[1];
      }
      
      if (!vimeoId) return url;
      
      const params = new URLSearchParams({
        autoplay: isPreview ? '0' : '1',
        loop: '1',
        byline: '0',
        title: '0',
        muted: isPreview ? '1' : '0',
        controls: '1',
        quality: isPreview ? '360p' : '1080p',
        portrait: '0',
        sidedock: '0',
        transparent: '1',
        playsinline: '1',
        background: '0'
      }).toString();
    
      return `https://player.vimeo.com/video/${vimeoId}?${params}`;
    }
    
    return url;
  };

  const getClearbitLogoUrl = (companyName) => {
    if (!companyName) return '';
    return `https://logo.clearbit.com/${companyName.toLowerCase().replace(/\s+/g, '')}.com`;
  };

  const getCompanyInitials = (companyName) => {
    if (!companyName) return '';
    return companyName
      .split(' ')
      .map(word => word[0])
      .join('')
      .toUpperCase()
      .slice(0, 2);
  };

  const navigateToPattern = (targetPattern) => {
    if (!targetPattern) return;
    const companySlug = targetPattern.Company.toLowerCase().replace(/\s+/g, '-');
    const categorySlug = targetPattern['Interaction category'].toLowerCase().replace(/\s+/g, '-');
    const nameSlug = targetPattern['Interaction name'].toLowerCase().replace(/\s+/g, '-');
    navigate(`/${companySlug}/${categorySlug}/${nameSlug}`);
  };

  const renderSelectedMedia = () => {
    if (!pattern) return null;

    if (selectedMediaIndex === 0 && pattern['Interaction link']) {
      return (
        <div className="relative w-full h-full">
          {videoLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-black">
              <div className="animate-pulse text-gray-400">Loading video...</div>
            </div>
          )}
          <iframe
            src={getVideoEmbedUrl(pattern['Interaction link'])}
            className="absolute inset-0 w-full h-full"
            frameBorder="0"
            allowFullScreen
            allow="autoplay; fullscreen; picture-in-picture"
            onLoad={() => setVideoLoading(false)}
          />
        </div>
      );
    }

    if (pattern.screenshots && pattern.screenshots.length > 0) {
      const screenshotIndex = selectedMediaIndex - 1;
      const screenshot = pattern.screenshots[screenshotIndex];
      
      if (screenshot) {
        return (
          <img
            src={screenshot}
            alt={`${pattern['Interaction name']} - Screenshot ${screenshotIndex + 1}`}
            className="absolute inset-0 w-full h-full object-contain bg-black"
          />
        );
      }
    }

    return (
      <div className="absolute inset-0 flex items-center justify-center bg-black text-gray-400">
        No media available
      </div>
    );
  };

  const renderLinks = () => {
    const hasSource = pattern.Source && pattern.Source !== 'NA';
    const hasVisit = pattern.Visit && pattern.Visit !== 'NA';

    if (!hasSource && !hasVisit) return null;

    return (
      <div className="col-span-1 sm:col-span-2 lg:col-span-3">
        <span className="text-gray-400 text-sm font-medium block mb-2">Links</span>
        <div className="flex flex-wrap items-center gap-4">
          {hasSource && (
            <a
              href={pattern.Source}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-300 text-base inline-flex items-center gap-1.5 transition-colors"
            >
              Source link <ExternalLink size={16} />
            </a>
          )}
          {hasVisit && (
            <a
              href={pattern.Visit}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 hover:text-blue-300 text-base inline-flex items-center gap-1.5 transition-colors"
            >
              Visit website <ExternalLink size={16} />
            </a>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="text-white text-lg">Loading...</div>
      </div>
    );
  }

  if (!pattern) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="text-white text-lg">Pattern not found</div>
      </div>
    );
  }

  const hasScreenshots = pattern.screenshots && pattern.screenshots.length > 0;

  return (
    <div className="min-h-screen bg-black">
      <div className="max-w-[1200px] mx-auto px-4 md:px-6 lg:px-8 pt-16 md:pt-20 lg:pt-24 pb-32">
        {/* Back Navigation */}
        <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-6 md:mb-8 w-full px-4 md:px-8">
          <div className="flex items-center gap-3 mb-4 sm:mb-0">
            <button 
              onClick={() => navigate(location.state?.from || '/')}
              className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
              aria-label="Go back"
            >
              <ArrowLeft size={16} />
            </button>
            <div className="flex items-center gap-3">
              <div className="w-8 h-8 rounded bg-[#1A1A1A] flex items-center justify-center overflow-hidden">
                {logoError ? (
                  <span className="text-sm font-medium">{getCompanyInitials(pattern.Company)}</span>
                ) : (
                  <img
                    src={getClearbitLogoUrl(pattern.Company)}
                    alt={pattern.Company}
                    className="w-full h-full object-contain p-1.5"
                    onError={() => setLogoError(true)}
                  />
                )}
              </div>
              <div className="flex flex-col sm:flex-row sm:items-center gap-1 sm:gap-2">
                <span className="text-gray-200 text-base font-medium">{pattern.Company}</span>
                <span className="hidden sm:block text-gray-400">/</span>
                <span className="text-gray-400 text-base">{pattern['Interaction name']}</span>
              </div>
            </div>
          </div>
          <span className="text-gray-400 font-mono text-sm">
            #{pattern['Serial number']}
          </span>
        </div>

        <div className="w-full space-y-6 md:space-y-8">
          {/* Main Media Display */}
          <div className="relative aspect-video w-full rounded-xl overflow-hidden bg-black shadow-2xl">
            {renderSelectedMedia()}
          </div>

          {/* Media Selector */}
          {hasScreenshots && (
            <div className="flex gap-2 overflow-x-auto px-4 md:px-8 pb-2 scrollbar-thin scrollbar-thumb-gray-700 scrollbar-track-transparent">
              {pattern['Interaction link'] && (
                <button
                  onClick={() => setSelectedMediaIndex(0)}
                  className={`relative flex-shrink-0 aspect-video w-28 md:w-32 rounded-lg overflow-hidden transition-all ${
                    selectedMediaIndex === 0 
                      ? 'ring-2 ring-white ring-offset-2 ring-offset-black' 
                      : 'opacity-50 hover:opacity-100'
                  }`}
                >
                  <iframe
                    src={getVideoEmbedUrl(pattern['Interaction link'], true)}
                    className="absolute inset-0 w-full h-full pointer-events-none"
                    frameBorder="0"
                  />
                </button>
              )}

              {pattern.screenshots.map((screenshot, index) => (
                <button
                  key={index}
                  onClick={() => setSelectedMediaIndex(index + 1)}
                  className={`relative flex-shrink-0 aspect-video w-28 md:w-32 rounded-lg overflow-hidden transition-all ${
                    selectedMediaIndex === index + 1 
                      ? 'ring-2 ring-white ring-offset-2 ring-offset-black' 
                      : 'opacity-50 hover:opacity-100'
                  }`}
                >
                  <img
                    src={screenshot}
                    alt={`Screenshot ${index + 1}`}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </button>
              ))}
            </div>
          )}
        </div>

        <div className="w-full space-y-8 md:space-y-10 px-4 md:px-8 mt-8 md:mt-12">
          {/* Pattern Info */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            <div className="space-y-1.5">
              <span className="text-gray-400 text-sm font-medium block">Device</span>
              <span className="text-gray-100 text-base leading-relaxed">{pattern.Device}</span>
            </div>
            <div className="space-y-1.5">
              <span className="text-gray-400 text-sm font-medium block">Category</span>
              <span className="text-gray-100 text-base leading-relaxed">{pattern['Interaction category']}</span>
            </div>
            <div className="space-y-1.5">
              <span className="text-gray-400 text-sm font-medium block">Industry</span>
              <span className="text-gray-100 text-base leading-relaxed">{pattern.Industry}</span>
            </div>
            {renderLinks()}
          </div>

          {/* Tags */}
          {pattern.Tags && pattern.Tags.length > 0 && (
            <div className="space-y-2">
              <span className="text-gray-400 text-sm font-medium block">Tags</span>
              <div className="flex flex-wrap items-center gap-2">
                {pattern.Tags.map((tag, index) => (
                  <React.Fragment key={index}>
                    <span className="text-base text-gray-300">#{tag}</span>
                    {index !== pattern.Tags.length - 1 && (
                      <span className="text-gray-600 last:hidden">•</span>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}

          {/* UX Problem */}
          {pattern['UX Problem solved'] && (
            <div className="space-y-2">
              <span className="text-gray-400 text-sm font-medium block">UX Problem solved</span>
              <p className="text-gray-100 text-base leading-relaxed">{pattern['UX Problem solved']}</p>
            </div>
          )}

          {/* Commentary */}
          {pattern.Commentary && (<div className="space-y-2">
              <span className="text-gray-400 text-sm font-medium block">Commentary</span>
              <p className="text-gray-100 text-base leading-relaxed whitespace-pre-wrap">{pattern.Commentary}</p>
            </div>
          )}
        </div>
      </div>

      {/* Pattern Navigation */}
      <div className="fixed bottom-8 left-0 right-0 flex justify-center items-center gap-3 z-50 px-4">
        <button
          onClick={() => navigateToPattern(prevPattern)}
          disabled={!prevPattern}
          className={`flex items-center gap-2 px-4 py-2.5 rounded-lg bg-white/10 backdrop-blur-sm 
            ${prevPattern ? 'hover:bg-white/20' : 'opacity-50 cursor-not-allowed'}
            transition-all duration-200`}
        >
          <ChevronLeft size={16} className="text-white" />
          <span className="text-white text-sm font-medium">Previous</span>
        </button>
        
        <button
          onClick={() => navigateToPattern(nextPattern)}
          disabled={!nextPattern}
          className={`flex items-center gap-2 px-4 py-2.5 rounded-lg bg-white/10 backdrop-blur-sm 
            ${nextPattern ? 'hover:bg-white/20' : 'opacity-50 cursor-not-allowed'}
            transition-all duration-200`}
        >
          <span className="text-white text-sm font-medium">Next</span>
          <ChevronRight size={16} className="text-white" />
        </button>
      </div>
    </div>
  );
};

export default PatternDetail;