// src/components/patterns/CategoryDescription.js
import React from 'react';

const categoryDescriptions = {
  "Feedback": "Everything that helps you understand what's happening with your request. When you ask the AI to do something, these are the signals and messages that keep you informed about its progress - whether it's just started working, halfway done, or almost finished with your request.",
  "Prompt": "This is your starting point - where you express what you want the AI to do. It includes all the ways you can communicate your ideas, instructions, or requests to the AI. Think of it as the conversation starter, where you set the direction for what you want to create or accomplish.",
  "Generation": "This is where your ideas come to life through the AI. After you've made your request, this is where you see the results take shape. It's the space where the AI presents its work, showing you what it understood from your prompt and how it transformed that into something concrete.",
  "Invisible": "These are the thoughtful features that work quietly in the background, making everything run smoothly without drawing attention to themselves. They're like having a silent assistant who anticipates needs and handles important tasks automatically, improving your experience without interrupting your flow.",
  "Concepts": "These are forward-thinking ideas and designs that show what AI interactions could look like in the future. They represent new ways we might work with AI - possibilities that designers and researchers are exploring but haven't fully built yet. Think of them as sketches of tomorrow's AI tools, showing potential new ways for humans and AI to work together more effectively."
};

const CategoryDescription = ({ category }) => {
  if (category === 'All' || !categoryDescriptions[category]) {
    return null;
  }

  return (
    <div className="rounded-lg border border-[#2A2929] bg-[#1A1A1A] p-4 mt-4">
      <p className="text-gray-300 text-sm leading-relaxed">
        {categoryDescriptions[category]}
      </p>
    </div>
  );
};

export default CategoryDescription;