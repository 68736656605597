// src/utils/sheetsHelper.js
// const SHEET_ID = '1FaDQ74KK8I68HYdaHerhws9KuZO7kVz3ecHssaSFchE';
// const SHEET_NAME = 'Sheet1';

// export const fetchSheetData = async () => {
//   try {
//     const url = `https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:json&sheet=${SHEET_NAME}`;
//     const response = await fetch(url);
//     const text = await response.text();
    
//     // Remove the Google Sheets JSON security prefix and suffix
//     const jsonString = text.substring(text.indexOf('{'), text.lastIndexOf('}') + 1);
//     const data = JSON.parse(jsonString);

//     // Map the column labels (ensuring exact matches with sheet headers)
//     const headers = data.table.cols.map(col => col.label.trim());

//     // Transform rows into objects with exact header matches
//     const rows = data.table.rows
//       .filter(row => row.c[0] !== null) // Filter out empty rows
//       .map(row => {
//         const item = {};
//         headers.forEach((header, index) => {
//           const value = row.c[index];
          
//           // Process specific fields
//           switch(header) {
//             case 'Tags':
//               // Convert comma-separated tags into an array
//               item[header] = value ? value.v.split(',').map(tag => tag.trim()) : [];
//               break;
            
//             case 'Commentary':
//               // Store commentary as is, will be used for the modal
//               item[header] = value ? value.v : '';
//               break;

//             case 'Date added':
//               // Convert date to a more readable format
//               item[header] = value ? new Date(value.v).toLocaleDateString() : '';
//               break;

//             case 'Featured':
//               // Convert to boolean
//               item[header] = value ? value.v === 'YES' : false;
//               break;

//             case 'Serial number':
//               // Ensure serial number is padded to 3 digits
//               item[header] = value ? value.v.toString().padStart(3, '0') : '';
//               break;

//             default:
//               // Default handling for other fields
//               item[header] = value ? value.v : '';
//           }
//         });

//         // Add computed properties
//         item.displayId = `#${item['Serial number']}`; // Format ID for display
//         item.processedTags = item.Tags.join(', '); // Join tags for display
        
//         // Process commentary into sections if it contains specific markers
//         if (item.Commentary) {
//           const sections = item.Commentary.split('\n\n').reduce((acc, section) => {
//             const [title, ...content] = section.split(':');
//             if (content.length) {
//               acc[title.trim().toLowerCase()] = content.join(':').trim();
//             }
//             return acc;
//           }, {});
          
//           item.processedCommentary = {
//             overview: sections.overview || '',
//             insights: sections.insights || '',
//             implementation: sections.implementation || '',
//             rawText: item.Commentary
//           };
//         }

//         return item;
//       });

//     console.log('Processed Data:', rows); // Debug log
//     return rows;
//   } catch (error) {
//     console.error('Error fetching sheet data:', error);
//     throw error;
//   }
// };

// // Helper function to format data for display
// export const formatPatternData = (pattern) => {
//   return {
//     ...pattern,
//     formattedDate: new Date(pattern['Date added']).toLocaleDateString('en-US', {
//       year: 'numeric',
//       month: 'short',
//       day: 'numeric'
//     }),
//     tagsList: pattern.Tags,
//     id: pattern.displayId,
//     hasCommentary: Boolean(pattern.Commentary),
//   };
// };

// src/utils/sheetsHelper.js
const SHEET_ID = '1FaDQ74KK8I68HYdaHerhws9KuZO7kVz3ecHssaSFchE';
const SHEET_NAME = 'Sheet1';

// Helper function to format video URLs (Vimeo)
const formatVideoUrl = (url) => {
  if (!url) return '';
  
  // Handle Vimeo URLs
  if (url.includes('vimeo.com')) {
    // If it's already an embed URL, return as is
    if (url.includes('player.vimeo.com')) {
      return url;
    }
    // Convert regular Vimeo URL to embed URL
    const videoId = url.match(/vimeo\.com\/(\d+)/)?.[1];
    if (videoId) {
      return `https://player.vimeo.com/video/${videoId}`;
    }
  }
  
  return url;
};

// Helper function to validate screenshot URLs
const validateScreenshotUrl = (url) => {
  if (!url || url === 'NA') return false;
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export const fetchSheetData = async () => {
  try {
    const url = `https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:json&sheet=${SHEET_NAME}`;
    const response = await fetch(url);
    const text = await response.text();
    
    const jsonString = text.substring(text.indexOf('{'), text.lastIndexOf('}') + 1);
    const data = JSON.parse(jsonString);

    const headers = data.table.cols.map(col => col.label.trim());

    const rows = data.table.rows
      .filter(row => row.c[0] !== null)
      .map(row => {
        const item = {};
        headers.forEach((header, index) => {
          const value = row.c[index];
          
          switch(header) {
            case 'Tags':
              item[header] = value ? value.v.split(',').map(tag => tag.trim()) : [];
              break;
            
            case 'Commentary':
              item[header] = value ? value.v : '';
              break;

            case 'Date added':
              item[header] = value ? new Date(value.v).toLocaleDateString() : '';
              break;

            case 'Featured':
              item[header] = value ? value.v === 'YES' : false;
              break;

            case 'Serial number':
              item[header] = value ? value.v.toString().padStart(3, '0') : '';
              break;

            case 'Interaction link':
              // Format video URLs for embedding
              item[header] = value ? formatVideoUrl(value.v) : '';
              break;

            case 'Screenshot 1':
            case 'Screenshot 2':
            case 'Screenshot 3':
              // Handle screenshot URLs
              item[header] = value && validateScreenshotUrl(value.v) ? value.v : null;
              break;

            default:
              item[header] = value ? value.v : '';
          }
        });

        // Add computed properties
        item.displayId = `#${item['Serial number']}`;
        item.processedTags = item.Tags.join(', ');
        
        // Process commentary sections
        if (item.Commentary) {
          const sections = item.Commentary.split('\n\n').reduce((acc, section) => {
            const [title, ...content] = section.split(':');
            if (content.length) {
              acc[title.trim().toLowerCase()] = content.join(':').trim();
            }
            return acc;
          }, {});
          
          item.processedCommentary = {
            overview: sections.overview || '',
            insights: sections.insights || '',
            implementation: sections.implementation || '',
            rawText: item.Commentary
          };
        }

        // Collect and validate screenshots
        item.screenshots = [
          item['Screenshot 1'],
          item['Screenshot 2'],
          item['Screenshot 3']
        ].filter(url => url !== null);

        // Add media flags
        item.hasScreenshots = item.screenshots.length > 0;
        item.hasVideo = Boolean(item['Interaction link']);

        return item;
      });

    console.log('Processed Data:', rows);
    return rows;
  } catch (error) {
    console.error('Error fetching sheet data:', error);
    throw error;
  }
};

export const formatPatternData = (pattern) => {
  return {
    ...pattern,
    formattedDate: new Date(pattern['Date added']).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }),
    tagsList: pattern.Tags,
    id: pattern.displayId,
    hasCommentary: Boolean(pattern.Commentary),
    hasScreenshots: pattern.hasScreenshots || false,
    hasVideo: pattern.hasVideo || false,
    screenshots: pattern.screenshots || [],
  };
};

// Helper function to get primary screenshot
export const getPrimaryScreenshot = (pattern) => {
  return pattern.screenshots && pattern.screenshots.length > 0 
    ? pattern.screenshots[0] 
    : null;
};

export default {
  fetchSheetData,
  formatPatternData,
  getPrimaryScreenshot
};