// src/components/patterns/PatternGrid.js
import React from 'react';
import PatternCard from './PatternCard';

const PatternGrid = ({ patterns, onPatternClick }) => {  // Renamed prop for clarity
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {patterns.map((pattern) => (
        <PatternCard
          key={pattern['Interaction name']}
          pattern={pattern}
          onPatternClick={onPatternClick}  // Pass through the callback
        />
      ))}
    </div>
  );
};

export default PatternGrid;